
let injectedScript = false

export const onInitialClientRender = () => {
    function addJS(jsCode) {
        var s = document.createElement(`script`);
        s.type = `text/javascript`;
        s.innerText = jsCode;
        document.getElementsByTagName(`head`)[0].appendChild(s);
    }
    // InstrumentationKey for appInsights is held in .env files
    if (!injectedScript) {    
         addJS(`
        var sdkInstance = "appInsightsSDK"; window[sdkInstance] = "appInsights"; var aiName = window[sdkInstance], aisdk = window[aiName] || function (e) { function n(e) { t[e] = function () { var n = arguments; t.queue.push(function () { t[e].apply(t, n) }) } } var t = { config: e }; t.initialize = !0; var i = document, a = window; setTimeout(function () { var n = i.createElement("script"); n.src = e.url || "https://az416426.vo.msecnd.net/scripts/b/ai.2.min.js", i.getElementsByTagName("script")[0].parentNode.appendChild(n) }); try { t.cookie = i.cookie } catch (e) { } t.queue = [], t.version = 2; for (var r = ["Event", "PageView", "Exception", "Trace", "DependencyData", "Metric", "PageViewPerformance"]; r.length;)n("track" + r.pop()); n("startTrackPage"), n("stopTrackPage"); var s = "Track" + r[0]; if (n("start" + s), n("stop" + s), n("setAuthenticatedUserContext"), n("clearAuthenticatedUserContext"), n("flush"), !(!0 === e.disableExceptionTracking || e.extensionConfig && e.extensionConfig.ApplicationInsightsAnalytics && !0 === e.extensionConfig.ApplicationInsightsAnalytics.disableExceptionTracking)) { n("_" + (r = "onerror")); var o = a[r]; a[r] = function (e, n, i, a, s) { var c = o && o(e, n, i, a, s); return !0 !== c && t["_" + r]({ message: e, url: n, lineNumber: i, columnNumber: a, error: s }), c }, e.autoExceptionInstrumented = !0 } return t }(
            {
                connectionString: "InstrumentationKey=${process.env.INSIGHTS};EndpointSuffix=applicationinsights.us"
            }
        ); window[aiName] = aisdk, aisdk.queue && 0 === aisdk.queue.length && aisdk.trackPageView({});
        window.appInsights = appInsights;
        appInsights.trackPageView();

     `);
        injectedScript = true;
    }
    
} 
export const onClientEntry = () => {
   //window.appInsights.trackPageView();
   var isIE = document.body.style.msTouchAction !== undefined;
   var isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
    if(isIE || isEdge){
        document.getElementsByTagName("body")[0].innerHTML = 
        `<div class="grid-row flex-align-center flex-justify-center" style="height: 100vh;">
            <div style="max-width: 880px;">
            <h2>The browser that you are using is not supported by this site. Please use Google Chrome, Microsoft Chromium Edge, Safari, or Mozilla Firefox for the best experience.</h2>
            <hr/>
            <h2>El navegador que está utilizando no es compatible con esta página web. Utilize Google Chrome, Microsoft Chromium Edge, Safari or Mozilla Firefox para un mejor resultado.</h2>
            </div>
        </div>`
    }
}

export const onRouteUpdate = () => {
    navigator.serviceWorker.register('/sw.js').then((reg) => {
      reg.update();
    });
  };
  
  export const onServiceWorkerUpdateReady = () => {
    window.location.reload(true)
  };

